<script>
export default {
  name: "IconBluesky"
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
    <path
      transform="scale(0.85) translate(1.8,3.5)"
      d="M5.43 1.762c2.66 1.997 5.52 6.046 6.571 8.218 1.05-2.172 3.911-6.221 6.571-8.218 1.92-1.441 5.029-2.556 5.029.992 0 .708-.406 5.952-0.644 6.803-.828 2.959-3.846 3.714-6.53 3.257 4.692.799 5.886 3.444 3.308 6.089-4.896 5.024-7.037-1.26-7.585-2.87-.101-.295-.148-.433-.149-.316-.001-.117-.048.021-.148.316-.549 1.61-2.69 7.894-7.586 2.87-2.578-2.645-1.384-5.29 3.308-6.089-2.684.457-5.702-.298-6.53-3.257-.238-.851-.644-6.095-.644-6.803 0-3.548 3.11-2.433 5.029-.992z"
    />
  </svg>
</template>
